@font-face {
  font-family: japanese;
  src: url("./assets/fonts/Japanese3017.otf") format("opentype"),
    url("./assets/fonts/Japanese3017.ttf") format("truetype");
}

html {
  font-family: japanese, sans-serif;
}

.twitter-timeline {
  border-color: #35282d !important;
  border-radius: 0.75rem;
}

#twitter-widget-0 {
  border-radius: 0.75rem;
}

body {
  color: #000;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
}

p,
span {
  word-break: break-word;
}
